import * as React from "react"

export default function StandardHeadContent(){
    return(
        <>
        <meta charSet="UTF-8"/>
            <meta name="robots" content="noindex"/>
                <meta name="googlebot" content="noindex"/>
                    <meta name="viewport"
                          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"/>
                        <link rel="icon" type="image/png" href="/icon16x16.png" sizes="16x16"/>
                            <link rel="icon" type="image/png" href="/icon24x24.png" sizes="24x24"/>
                                <link rel="icon" type="image/png" href="/icon32x32.png" sizes="32x32"/>
                                    <link rel="icon" type="image/png" href="/icon64x64.png" sizes="32x32"/>
                                        <link rel="icon" type="image/png" href="/icon96x96.png" sizes="96x96"/>
                                            <link rel="icon" type="image/png" href="/icon128x128.png" sizes="128x128"/>
                                                <link rel="icon" type="image/png" href="/icon256x256.png"
                                                      sizes="256x256"/>
                                                    <link rel="icon" type="image/png" href="/icon512x512.png"
                                                          sizes="512x512"/>
        </>
    )
}