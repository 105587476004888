import * as React from "react"
import {useState} from "react";
import { Link } from "gatsby"

export default function Menu({lang,changeLang}){
    const [expanded,setExpanded] = useState(false);

    return(
        <>
        <div id={"menu"}>
            <Link to={"/"}>
                <div id={"logo"}>
                </div>
            </Link>

            <div id={"hamburgerWrapper"} onClick={()=>{setExpanded(!expanded)}}>
                {lang != "en" && <>Meny</>}
                {lang == "en" && <>Menu</>}
                <div id={"hamburger"} className={expanded?"hamExpanded":""} >
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>


                <div className={expanded?"expanded longMenu":"longMenu"}>
                    <ul>

                        {lang != "en" && <li><Link to={"/reise"}>Reise</Link></li>}
                        {lang == "en" && <li><Link to={"/transport"}>Travel</Link></li>}


                        <li><Link to={"/overnatting"}>
                            {lang != "en" && <>Overnatting</>}
                            {lang == "en" && <>Accomodation</>}
                        </Link></li>

                        <li><Link to={"/seremoni-fest"}>
                            {lang != "en" && <>Seremoni og bryllupsfest</>}
                            {lang == "en" && <>Ceremony & wedding party</>}
                        </Link></li>

                        {/*<li><Link to={"/seremoni-fest"}>
                            {lang != "en" && <>Bryllupsfest</>}
                            {lang == "en" && <>Wedding party</>}
                        </Link></li>*/}

                        <li><Link to={"/dagen-for"}>
                            {lang != "en" && <>Sosialt arrangement dagen før</>}
                            {lang == "en" && <>Pre-wedding event</>}
                        </Link></li>

                        <li><Link to={"/covid"}>
                            {lang != "en" && <>Covid-restriksjoner</>}
                            {lang == "en" && <>Covid restrictions</>}
                        </Link></li>

                        <li><Link to={"/transport"}>
                            {lang != "en" && <>Transport og parkering</>}
                            {lang == "en" && <>Transport and parking</>}
                        </Link></li>

                        {/*<li><Link to={"/kart"}>
                            {lang != "en" && <>Kart</>}
                            {lang == "en" && <>Map</>}
                        </Link></li>*/}

                        <li><Link to={"/antrekk"}>
                            {lang != "en" && <>Antrekk</>}
                            {lang == "en" && <>Attire / dress code</>}
                        </Link></li>

                        <li><Link to={"/forlovere-toastmaster"}>
                            {lang != "en" && <>Forlovere</>}
                            {lang == "en" && <>Matron of honour & best men</>}
                        </Link></li>


                        <li><Link to={"/forlovere-toastmaster"}>
                            {lang != "en" && <>Toastmaster</>}
                            {lang == "en" && <>Toastmaster</>}
                        </Link></li>


                        <li><Link to={"/gaver"}>
                            {lang != "en" && <>Gaver</>}
                            {lang == "en" && <>Gifts</>}
                        </Link></li>

                        <li><Link to={"/rsvp"}>
                            {lang != "en" && <>RSVP</>}
                            {lang == "en" && <>RSVP</>}
                        </Link></li>

                        <br/>

                        <li><Link to={"/marathon"}>
                            {lang != "en" && <>Ting å gjøre i nærområdet</>}
                            {lang == "en" && <>Things to do in Marathon</>}
                        </Link></li>

                        {/*<li><Link to={"/ferie"}>
                            {lang != "en" && <>Kombinere med ferie</>}
                            {lang == "en" && <>Combine with vacation</>}
                        </Link></li>

                        <li><Link to={"/athen"}>
                            {lang != "en" && <>Ting å gjøre i Athen</>}
                            {lang == "en" && <>Things to do in Athens</>}
                        </Link></li>*/}


                        <li><Link to={"/santorini"}>
                            {lang != "en" && <>Være med til Santorini?</>}
                            {lang == "en" && <>Join our trip to Santorini?</>}
                        </Link></li>

                        <br/>

                        <li><Link to={"/sporsmal"}>
                            {lang != "en" && <>Spørsmål</>}
                            {lang == "en" && <>Questions</>}
                        </Link></li>

                    </ul>
                </div>

        </div>
            <div id={"langSwitch"}>
                {lang != "no" &&
                    <a onClick={()=>{changeLang("no")}}>Bytt til norsk</a>
                }
                {lang == "no" &&
                    <a onClick={()=>{changeLang("en")}}>Switch to English</a>
                }
            </div>
        </>
    )
}